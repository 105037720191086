import React from "react";
import Image from "../../elements/Image";
import { OptionsCard, OptionsRow, CardText } from "./ram.styles";

const LimitedStandard = () => {
  return (
    <OptionsRow>
      <OptionsCard>
        <Image fileName={'options_ANT@2x.jpg'} alt={'RAM Bighorn - opcje dodatkowe'}/>
        <CardText color={'#a7a7a7'} className={`text`}>
          GRUPA OCHRONY [ANT]

          <ul>
            <li>4 regulowane haki do mocowania ładunku</li>
            <li>Oświetlenie skrzynki ładunkowej</li>
            <li>Specjalistyczne zabezpieczenie skrzyni ładunkowej</li>
            <li>Przegroda ładunkowa (standard z Rambox)</li>
          </ul>
        </CardText>
      </OptionsCard>

      <OptionsCard>
        <Image fileName={'options_A63@2x.jpg'} alt={'RAM Bighorn - opcje dodatkowe'}/>
        <CardText color={'#a7a7a7'} className={`text`}>
          WYPOSAŻENIE 2 POZIOMU [A69]

          <ul>
            <li>19 głośników Harman Kardon</li>
            <li>Wentylowane tylne siedzenia</li>
            <li>12-calowy wyświetlacz</li>
          </ul>
        </CardText>
      </OptionsCard>

      <OptionsCard>
        <Image fileName={'options_SER@2x.jpg'} alt={'RAM Laramie - opcje dodatkowe'}/>
        <CardText color={'#a7a7a7'} className={`text`}>
          ZAWIESZENIE PNEUMATYCZNE [SER]

          <ul>
            <li>Zawieszenie pneumatyczne obejmuje pięć ustawień wysokości. Sterowanie w górę i w dół odbywa się za pomocą przycisku w środkowej konsoli pojazdu.</li>
            <li>Zapewnia łatwość wsiadania i wysiadania, wyższą wysokość jazdy dla dodatkowego prześwitu, a także niższą wysokość jazdy dla aerodynamiki i lepszej wydajności.</li>
          </ul>
        </CardText>
      </OptionsCard>

      <OptionsCard>
        <Image fileName={'options_ALP@2x.jpg'} alt={'RAM Laramie - opcje dodatkowe'}/>
        <CardText color={'#a7a7a7'} className={`text`}>
          ZAAWANSOWANA GRUPA BEZPIECZEŃSTWA [ALP]

          <ul>
            <li>Tempomat adaptacyjny z funkcją Stop & Go</li>
            <li>Ostrzeżenie przed kolizją</li>
            <li>Asystent utrzymania pasa ruchu</li>
            <li>Równoległy i prostopadły asystent parkowania z funkcją Stop</li>
            <li>Kamera 360 stopni</li>
          </ul>
        </CardText>
      </OptionsCard>

    </OptionsRow>
  )
}

export default LimitedStandard